import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: left;

  h1 {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1.2;
  }

  h2 {
    font-size: 20px;
    color: #000;
    opacity: 0.98;
    font-weight: 600;
    line-height: 1.3;
  }

  p {
    font-size: 16px;
    color: #1d1d1f;
    opacity: 0.7;
    font-weight: 500;
    letter-spacing: -0.015em;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  a {
    color: #006ddb;
  }

  .dotted-list {
    list-style-type: none;
  }

  .dotted-list li {
    position: relative;
    padding-left: 20px;
  }

  .dotted-list li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: black;
    font-size: 20px;
    line-height: 1;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 22px;
    }
  }
`;
