import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 2rem 0 12rem 0;
  background-color: #ffffff;

  .footer-cta-wrap 
    color: #1d1d1f;
    display: block;
    padding: 0;
    text-align: center;
    text-decoration: none;
  }

  .footer-cta-wrap .container {
    padding: 250px 0 250px 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: 100%;
  }

  .w-inline-block {
    display: inline-block;
    max-width: 100%;
  }

  .fluid-gradient-heading.fluid-gradient-heading--footer {
    margin-bottom: 32px;
  }

  .cta-link-icon {
    align-items: center;
    display: inline-block;
    height: 13px;
    justify-content: center;
    margin-left: 3px;
    position: relative;
    top: 2px;
    width: 11px;
  }
  .cta-link-icon.cta-link-icon--hero {
    height: 18px;
    margin-left: 5px;
    top: 3px;
    width: 14px;
  }
  .cta-link-text {
    display: inline-block;
  }
  .cta-link.cta-link--hero {
    font-size: 24px;
    font-weight: 500;
    color: #006ddb;
  }
  .cta-link-text {
    display: inline-block;
  }

  .cta-link-icon {
    height: 13.5px;
  }
  .cta-link-icon.cta-link-icon--hero {
    height: 18px;
    margin-left: 8px;
    width: 14px;
    font-size: 23px;
  }
  .fluid-gradient-heading {
    -webkit-text-fill-color: transparent;
    font-size: 92px;
    font-weight: 600;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-top: 0;
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .fluid-gradient-heading.fluid-gradient-heading--hero {
    line-height: 1.05;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .fluid-gradient-heading.fluid-gradient-heading--hero.cc-de {
    font-size: 87px;
  }

  .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
    font-size: 103px;
  }

  .fluid-gradient-heading {
    animation: intro-gradient 10s ease infinite both;
    background: linear-gradient(
      91.36deg,
      #eca658,
      #f391a6 13.02%,
      #e188c3 25.52%,
      #a58de3 37.5%,
      #56abec 49.48%,
      #737eb7 63.02%,
      #c8638c 72.92%,
      #dd5d57 84.38%,
      #df6c51 97.92%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
    background-size: 200% 200%;
  }

  @keyframes intro-gradient {
    0% {
      background-position: 0 4%;
    }
    50% {
      background-position: 100% 97%;
    }
    to {
      background-position: 0 4%;
    }
  }

  
  @media (max-width: 1024px) {
    .footer-cta-wrap {
      padding: 80px 0;
    }

    .fluid-gradient-heading {
      font-size: 72px;
    }

    .cta-link.cta-link--hero {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    .footer-cta-wrap {
      padding: 60px 0;
    }
    .cta-link-icon.cta-link-icon--hero {
    height: 15px;
    margin-left: 8px;
    width: 11px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
    margin-bottom: 10px;
    }

    .fluid-gradient-heading {
      font-size: 50px;
    }

    .cta-link.cta-link--hero {
      font-size: 16px;
    }

    .footer-cta-wrap .container {
    padding: 80px 0 80px 0;
    
  }
  }

  @media (max-width: 480px) {
    .footer-cta-wrap {
      padding: 40px 0;
    }

    .fluid-gradient-heading {
      font-size: 38px;
    }

    .cta-link.cta-link--hero {
      font-size: 16px;
    }
  }
`;
