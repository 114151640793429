import React from "react";
import styled from "@emotion/styled";

const FooterSection = styled.div`
  padding-top: 100px;
  .footer-menu-wrap {
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: -0.011em;
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .footer__link-wrap {
    margin-right: -12px;
  }
  .footer-link {
    display: inline-block;
    color: #1d1d1f;
    opacity: 0.7;
    padding: 6px 12px;
    text-decoration: none;
  }
  .footer-link:hover {
    opacity: 1;
  }
  .footer-cta-wrap {
    color: #1d1d1f;
    display: block;
    padding-bottom: 240px;
    padding-top: 240px;
    text-decoration: none;
  }
  .utility-page-wrap {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    width: 100vw;
  }

  .legal-text {
    font-weight: 500;
    letter-spacing: -0.015em;
  }

  .footer-legal {
    color: #1d1d1f;
    opacity: 0.7;
  }

  .divider {
    background-color: hsla(0, 0%, 53%, 0.2);
    height: 1px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    .footer-menu-wrap {
      align-items: center;
      flex-direction: column-reverse;
    }
    .footer__link-wrap {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-bottom: 18px;
    }
    .footer-link {
      margin-bottom: 16px;
      margin-left: 0;
    }
    .footer-cta-wrap {
      padding-bottom: 120px;
      padding-top: 120px;
    }
  }
`;

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="Container">
      <FooterSection>
        <footer className="footer">
          <div className="container">
            <div className="divider"></div>
            <div className="footer-menu-wrap">
              <div className="footer-legal">
                © {currentYear} Tineth Pathirage. All rights reserved.
              </div>
              <div className="footer__link-wrap">
                <a href="/reasons" className="footer-link">
                  10 Reasons for MERN
                </a>
                <a href="/privacy-policy" className="footer-link">
                  Privacy
                </a>
              </div>
            </div>
          </div>
        </footer>
      </FooterSection>
    </div>
  );
}

export default Footer;
