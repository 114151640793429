import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin-top: -12rem;
  @media screen and (max-width: 768px) {
    margin-top: -5rem;
  }
  margin-bottom: 10rem;
  p {
    font-size: 16px;
    color: #1d1d1f;
    opacity: 0.7;
    font-weight: 500;
  }
`;

export const Image = styled.img`
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

export const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -2rem;
`;

export const Tech = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  min-width: 100px;
  margin-bottom: 2rem;
`;

export const TechImg = styled.img`
  height: 40px;
  width: 40px;
  padding-bottom: 5px;
`;

export const TechName = styled.div`
  font-size: 14px;
`;
