// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "/project/ab.png",
    title: "AB Engineering",
    description:
      "The official website for Netherlands-based company AB Engineering & Consultancy.",
    tech_stack: ["React", "TailwindCSS", "JavaScript", "MongoDB"],
    github_url: "",
    demo_url: "https://abengineeringenconsultancy.com/",
  },
  {
    img: "/project/rora.png",
    title: "Rora Global",
    description:
      "Official website for Rora Global, offering seamless user experience and high performance.",
    tech_stack: ["HTML5", "CSS", "Php", "JavaScript", "jQuery"],
    github_url: "",
    demo_url: "https://www.rora.lk/",
  },
  {
    img: "/project/github.png",
    title: "GitHub Detective",
    description:
      "A web application that detects GitHub profiles using GitHub usernames.",
    tech_stack: ["React", "TailwindCSS", "NodeJs", "MongoDB"],
    github_url:
      "https://github.com/MrTineth/GitHub-Profile-Search-App-Using-React-JS",
    demo_url: "",
  },
  {
    img: "/project/shopbag.png",
    title: "Shop Bag",
    description:
      "E-commerce web application with dynamic listings, user authentication, and secure payments.",
    tech_stack: ["Laravel", "Vite", "Ajax", "Bootstrap", "MySQL"],
    github_url:
      "https://github.com/MrTineth/Laravel-E-Commerce-Web-Application",
    demo_url: "",
  },
  {
    img: "/project/personal-finance.png",
    title: "Personal Financier",
    description:
      "Personal finance system for tracking expenses, managing budgets, and achieving financial goals.",
    tech_stack: ["HTML5", "CSS", "Bootstrap", "Php", "MySQL"],
    github_url: "https://github.com/MrTineth/Personal-Finance-Management",
    demo_url: "",
  },
];

// --------------------------------------- Skills

export const stackList = [
  {
    img: "/tech/mongodb-original.svg",
    name: "MongoDB",
  },
  {
    img: "/tech/express-original.svg",
    name: "ExpressJs",
  },
  {
    img: "/tech/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "/tech/nodejs-original.svg",
    name: "NodeJS",
  },
  {
    img: "/tech/nextjs-original.svg",
    name: "NextJs",
  },
  {
    img: "/tech/typescript-original.svg",
    name: "Typescript",
  },
  {
    img: "/tech/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "/tech/laravel-original.svg",
    name: "Laravel",
  },
  {
    img: "/tech/firebase-plain.svg",
    name: "Firebase",
  },
  {
    img: "/tech/python-original.svg",
    name: "Python",
  },
  {
    img: "/tech/vite-original.svg",
    name: "Vite",
  },
  {
    img: "/tech/html5-original.svg",
    name: "HTML5",
  },
  {
    img: "/tech/css3-original.svg",
    name: "CSS3",
  },
  {
    img: "/tech/java-original.svg",
    name: "Java",
  },
  {
    img: "/tech/php-original.svg",
    name: "Php",
  },
  {
    img: "/tech/nodemon-original.svg",
    name: "Nodemon",
  },
  {
    img: "/tech/postman-original.svg",
    name: "Postman",
  },
  {
    img: "/tech/prisma-original.svg",
    name: "Prisma",
  },
  {
    img: "/tech/chakra-original.svg",
    name: "Chakra",
  },
  {
    img: "/tech/tailwindcss-original.svg",
    name: "TailwindCSS",
  },
  {
    img: "/tech/git-original.svg",
    name: "Git",
  },
  {
    img: "/tech/nestjs-original.svg",
    name: "NestJs",
  },
  {
    img: "/tech/supabase-original.svg",
    name: "Supabase",
  },
  {
    img: "/tech/azure-original.svg",
    name: "Azure",
  },
  {
    img: "/tech/mysql-original.svg",
    name: "MySQL",
  },
  {
    img: "/tech/cloudflare-original.svg",
    name: "Cloudflare",
  },
  {
    img: "/tech/sqlite-original.svg",
    name: "SQLite",
  },
  {
    img: "/tech/mariadb-original.svg",
    name: "MariaDB",
  },
  {
    img: "/tech/bootstrap-original.svg",
    name: "Bootstrap",
  },
  {
    img: "/tech/docker-original.svg",
    name: "Docker",
  },
  {
    img: "/tech/vercel-original.svg",
    name: "Vercel",
  },
  {
    img: "/tech/metamask-original.svg",
    name: "MetaMask",
  },
  {
    img: "/tech/figma-original.svg",
    name: "Figma",
  },
];
