import React from "react";
import { ContactWrapper } from "./RequestElements";

import ScrollAnimation from "react-animate-on-scroll";
function Request() {
  return (
    <ContactWrapper>
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <div class="footer-cta-wrap ix--cta w-inline-block">
            <div class="container container--center">
              <h4 class="fluid-gradient-heading fluid-gradient-heading--footer">
                Interested in working with me?
              </h4>
              <div class="cta-link cta-link--hero">
                <a
                  href="https://wa.me/+94763113219"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div class="cta-link-text">Start a project request</div>
                </a>
                <div class="cta-link-icon cta-link-icon--hero w-embed">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    data-reactroot=""
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="3.5"
                      stroke="currentColor"
                      d="M8 20L16 12L8 4"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Request;
