import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 10rem 0 6rem 0;
  @media (max-width: 768px) {
    margin: -5rem 0 6rem 0;
  }
`;

export const Email = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 1rem;

  span {
    font-size: 1rem;
    font-weight: 400;
    color: #1d1d1f;
    opacity: 0.7;
    font-weight: 500;
  }

  @media (min-width: 576px) {
    span {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 992px) {
    span {
      font-size: 1.5rem;
    }
  }
`;
