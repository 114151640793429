import React from "react";
import { useEffect } from "react";
import Header from "../components/Header/Header";
import ReasonsDetails from "../components/Reasons/Reasons";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/Scroll/ScrollToTop";
function Reasons() {
  useEffect(() => {
    document.title = "10 reasons your website should run on MERN";
  }, []);
  return (
    <>
      <Header />
      <ReasonsDetails />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default Reasons;
