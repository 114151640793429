import styled from "@emotion/styled";

export const Nav = styled.nav`
 
ul {
  margin-bottom: 10px;
  margin-top: 0;
  padding-left: 40px;
}
label {
  display: block;
  margin-bottom: 5px;
}
label,
strong {
  font-weight: 500;
}
.nav {
  background-color: hsla(0, 0%, 100%, 0.7);
  border-bottom: 1px solid #e2e2e2;
  bottom: auto;
  left: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  width: 90%;
}
.container.container--nav {
  position: relative;
  text-align: center;
}
.container.container--center,
.container.container--large {
  max-width: 1440px;
  padding-left: 28px;
  padding-right: 28px;
  width: 100%;
}

.link__bg {
  background-color: #f7f7f7;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  bottom: 0;
  color: #1d1d1f;
  display: inline-block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
}
.link__bg.link__bg--hero {
  border-radius: 12px;
}
.nav__logo {
  align-items: center;
  bottom: 0;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.025em;
  margin-left: -18px;
  position: absolute;
  right: auto;
  text-decoration: none;
  top: 0;
}
.nav__link {
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.011em;
  padding: 8px 18px;
  position: relative;
  text-decoration: none;
  z-index: 102;
}
.nav__link,
.nav__link.w--current {
  font-weight: 500;
}
.link__content {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: 102;
}
.link__content:active {
  opacity: 0.7;
}
.cta-link {
  align-items: center;
  color: #006ddb;
  display: block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.025em;
  text-decoration: none;
  white-space: nowrap;
}

.cta-link.cta__link--nav {
  bottom: 0;
  font-size: 16px;
  left: auto;
  margin-right: -18px;
  padding: 8px 18px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 102;
}

.nav__logo__link {
  display: block;
  padding: 7px 18px;
  position: relative;
  text-decoration: none;
  z-index: 102;
}

.cta-link-icon {
  align-items: center;
  display: inline-block;
  height: 13px;
  justify-content: center;
  margin-left: 6px;
  position: relative;
  top: 2px;
  width: 11px;
}

.cta-link-text {
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .nav__logo {
    margin-left: 0;
  }

  .cta-link.cta__link--nav {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .nav__logo__link {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .nav {
    padding-top: 12px;
  }
  .container.container--nav {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: space-between;
  }
  .nav__menu {
    display: none;
  }
  .nav__logo {
    font-size: 18px;
    position: static;
  }
  .cta-link {
    font-size: 16px;
  }
  .cta-link.cta__link--nav {
    position: static;
  }

  .nav__logo__link {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 479px) {
  .container.container--large {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
a {
  color: inherit;
}

  }
`;
