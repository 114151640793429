import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 0rem 0 10rem 0;
  * {
    box-sizing: border-box;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }

  .hosting-cad-img {
    border: 0;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    width: 300px;
    padding-top: 20px;
  }

  .w-layout-grid {
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .section {
    color: #1d1d1f;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    min-height: 100%;
  }

  .section-heading-eyebrow {
    font-size: 28px;
    letter-spacing: -0.04em;
    line-height: 1.2;
    margin-bottom: 16px;
    margin-top: 0;
    opacity: 0.75;
    padding-left: 0.1em;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }
  ul {
    margin-bottom: 10px;
    margin-top: 0;
    padding-left: 40px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  label,
  strong {
    font-weight: 500;
  }
  .nav {
    background-color: hsla(0, 0%, 100%, 0.7);
    border-bottom: 1px solid #e2e2e2;
    bottom: auto;
    left: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: 90%;
  }

  .container.container--hero {
    bottom: auto;
    height: 100vh;
    justify-content: center;
    left: 0;
    max-width: none;
    padding-bottom: 100px;
    padding-top: 100px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .container.container--legal {
    max-width: 680px;
  }
  .container.container--large {
    max-width: 1440px;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
  }

  .section {
    padding-bottom: 140px;
    padding-top: 140px;
  }
  .section.section--gray.cc-reasons,
  .section.section--gray.section--faq {
    padding-top: 0;
  }
  .section.section--workflow {
    height: 100vh;
    overflow: hidden;
  }
  .section.section--manifest {
    padding-bottom: 160px;
    padding-top: 100px;
  }

  .section-heading {
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 890px;
  }

  .section-header {
    margin-bottom: 142px;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
    width: 100%;
  }

  .intro-card-description {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.025em;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 24px;
    opacity: 0.75;
    width: 100%;
  }

  .intro-grid {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    margin-bottom: 28px;
  }
  .intro-grid.intro-grid--variant {
    grid-template-columns: 0.4fr 1fr;
    grid-template-rows: auto;
  }
  .intro-card {
    background-color: #fff;
    border: 1px solid hsla(0, 0%, 51%, 0.16);
    border-radius: 28px;
    padding: 64px;
    position: relative;
  }
  .intro-card.intro-grid-cell--animation {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    align-items: center;
    background-color: #1d1d1f;
    background-image: linear-gradient(120deg, #eb4f56, #9d66e9);
    border-style: none;
    color: #fff;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .intro-card.intro-grid-cell--flex {
    display: flex;
    flex-direction: column;
  }
  .intro-card.intro-card--performance {
    overflow: hidden;
  }

  .intro-card.intro-card--responsive {
    align-items: flex-start;
    display: flex;
    padding-bottom: 0;
    padding-top: 58px;
  }
  .intro-card.intro-card--tiny {
    padding-left: 52px;
    padding-right: 52px;
  }
  .intro-card.intro-card--tiny.cc-dev-speed {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .intro-card.intro-card--tiny.cc-code {
    padding-right: 32px;
  }
  .intro-card.intro-card--scale {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
  }
  .intro-card.intro-card--stats-cell {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    text-align: center;
  }

  .intro-card-heading {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.025em;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: 0;
  }
  .intro-card-heading.intro-cell-heading--big {
    font-size: 44px;
  }
  .intro-card-heading.intro-cell-heading--center {
    text-align: center;
  }
  .intro-card-heading.intro-card-heading--tiny {
    font-size: 18px;
  }
  .intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
    bottom: auto;
    left: 52px;
    position: absolute;
    right: auto;
    top: 64px;
  }
  .intro-card-heading.intro-card-heading--scale {
    bottom: auto;
    left: 64px;
    max-width: 350px;
    position: absolute;
    right: auto;
    top: 64px;
  }
  .stats-grid {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }
  .intro-stats-figure {
    display: inline;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: -0.05em;
    line-height: 1;
    margin-left: -2px;
  }
  .stats-figure-wrap {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .stats-figure-wrap.stats-figure-wrap--customers {
    background-image: linear-gradient(225deg, #5af141, #2a9dce);
  }
  .stats-figure-wrap.stats-figure-wrap--years {
    background-image: linear-gradient(135deg, #63e3dc, #1254c2);
  }
  .stats-figure-wrap.stats-figure-wrap--projects {
    background-image: linear-gradient(225deg, #e374ff, #3d30ea);
  }
  .stats-figure-wrap.stats-figure-wrap--passion {
    background-image: linear-gradient(135deg, #ffb077, #b82698);
  }
  .stats-descriptoin {
    font-weight: 500;
    letter-spacing: -0.025em;
    margin-top: 4px;
    opacity: 0.75;
  }
  .intro-stats-symbol {
    display: inline;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.025em;
    padding-left: 3px;
  }
  .intro-stats-symbol.intro-stats-symbol--plus {
    font-size: 32px;
    line-height: 0.6;
  }
  .intro-stats-symbol.intro-stats-symbol--years {
    align-self: flex-end;
    padding-left: 5px;
  }
  .intro-stats-symbol.intro-stats-symbol--percent {
    align-self: flex-end;
  }

  .intro-card-code-wrap {
    font-family: Ibm plex mono, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 52px;
    max-width: 540px;
  }
  .intro-card-code-indented {
    margin-left: 26px;
  }
  .code-color {
    color: #e62615;
  }

  .intro-card-performance-img {
    margin-bottom: -26px;
    margin-left: -64px;
    margin-top: 36px;
    max-width: 600px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 0 50%;
    object-position: 0 50%;
    transform-origin: 100% 0;
    width: 120%;
  }

  .intro-card.intro-card--integrations {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 0 97px;
    text-align: center;
    transform: translate(0);
  }

  .intro-card.intro-card--pixel {
    align-items: center;
    background-color: #1d1d1f;
    background-image: url(pixel-pattern.webp),
      linear-gradient(45deg, #16327c, #1ebf98);
    background-position: 50% 0, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: cover, auto;
    border-style: none;
    color: #f5f5f7;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .intro-cad-responsive-img {
    margin-left: -136px;
    min-height: 540px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .reaons-banner-wrap {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scaleX(1none) translate(0);
    transform-style: preserve-3d;
    transition: transform 0.2s;
  }
  .reaons-banner-wrap:hover {
    transform: scale(1.02);
  }

  .intro-card.intro-card--reasons-banner {
    align-items: center;
    background-image: linear-gradient(45deg, #b94cdb, #e26092 50%, #fe815e);
    border-style: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    font-weight: 600;
    justify-content: space-between;
    letter-spacing: -0.015em;
    padding-bottom: 32px;
    padding-top: 42px;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.2s;
  }
  .intro-card.intro-card--reasons-banner:hover {
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.22);
  }

  .w-inline-block {
    display: inline-block;
    max-width: 100%;
  }

  .reasons-banner-heading {
    max-width: 440px;
  }

  .cta-link {
    align-items: center;
    color: #006ddb;
    display: block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.025em;
    text-decoration: none;
    white-space: nowrap;
  }

  .cta-link.cta-link--hero.cc-reasons {
    color: #f5f5f7;
    padding: 0;
  }

  .cta-link.cc-reasons-banner {
    color: #fff;
    margin-top: 12px;
    padding: 8px 16px;
  }

  .cta-link-icon {
    align-items: center;
    display: inline-block;
    height: 13px;
    justify-content: center;
    margin-left: 3px;
    position: relative;
    top: 2px;
    width: 11px;
  }

  .cta-link-text {
    display: inline-block;
  }

  .w-embed:after,
  .w-embed:before {
    content: " ";
    display: table;
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
  }
  .w-embed:after {
    clear: both;
  }

  #node-8-reasons {
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 1;
    grid-column-end: span 2;
    grid-column-start: span 2;
    grid-row-end: span 1;
    grid-row-start: span 1;
  }
  @media screen and (max-width: 991px) {
    #node-8-reasons {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      grid-column-start: span 1;
    }
  }

  @media screen and (max-width: 991px) {
    a {
      cursor: auto;
    }
    .container.container--hero {
      height: auto;
      justify-content: space-around;
      min-height: 100vh;
      padding-bottom: 80px;
      padding-top: 114px;
      position: static;
    }

    .hero {
      height: auto;
    }
    .hero-heading {
      font-size: 72px;
    }
    .section-header {
      margin-bottom: 80px;
    }
    .section {
      padding-bottom: 80px;
      padding-top: 80px;
    }
    .section-heading-eyebrow {
      font-size: 26px;
    }
    .section.section--manifest {
      display: none;
    }
    .section-heading {
      font-size: 72px;
      max-width: 650px;
    }
    .section-heading.section-heading--faq {
      font-size: 56px;
    }
    .intro-card-description {
      line-height: 1.4;
      margin-top: 18px;
    }

    .section-header {
      margin-bottom: 80px;
    }

    .hero-eyebrow {
      font-size: 24px;
      font-weight: 500;
    }
    .intro-grid,
    .intro-grid.intro-grid--variant {
      grid-template-columns: 1fr;
    }
    .intro-card.intro-grid-cell--animation {
      grid-row-gap: 32px;
      grid-template-columns: 1fr;
    }
    .intro-card.intro-card--integrations {
      padding-bottom: 64px;
    }
    .intro-card.intro-card--pixel {
      background-position: 50% 100%, 0 0;
      background-size: cover, auto;
    }
    .intro-card.intro-card--tiny.cc-dev-speed {
      padding-bottom: 80px;
      padding-top: 110px;
    }
    .intro-card.intro-card--scale {
      height: 450px;
    }
    .intro-card.intro-card--stats-cell {
      padding-bottom: 48px;
      padding-top: 48px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 64px;
    }
    .intro-card-heading.intro-cell-heading--big {
      font-size: 40px;
    }

    .section-heading-eyebrow {
      font-size: 26px;
    }

    .intro-card-integrations-img {
      margin-bottom: 32px;
    }

    .intro-grid,
    .intro-grid.intro-grid--variant {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 767px) {
    .hero-heading {
      font-size: 64px;
    }
    .section-heading {
      font-size: 56px;
    }
    .section-heading.section-heading--faq {
      font-size: 48px;
    }
    .intro-card-description {
      font-size: 18px;
    }
    .intro-card-description.intro-card-description--animations {
      margin-top: 12px;
    }
    .section-heading-eyebrow {
      font-size: 24px;
      padding-left: 0.1em;
    }

    .hero-eyebrow {
      font-size: 22px;
    }
    .intro-card {
      padding: 56px 38px;
    }
    .intro-card.intro-card--integrations {
      padding-bottom: 65px;
    }
    .intro-card.intro-card--responsive {
      flex-direction: column;
      padding-top: 50px;
    }
    .intro-card.intro-card--reasons-banner {
      font-size: 22px;
    }
    .intro-card.intro-card--reasons-banner:hover {
      box-shadow: none;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 54px;
    }
    .intro-card-heading,
    .intro-card-heading.intro-cell-heading--big {
      font-size: 32px;
    }
    .intro-stats-figure {
      font-size: 56px;
    }
    .intro-stats-symbol.intro-stats-symbol--plus {
      font-size: 26px;
      top: -9px;
    }
    .intro-stats-symbol.intro-stats-symbol--years {
      bottom: 3px;
      font-size: 18px;
      padding-left: 3px;
    }

    .section-heading-eyebrow {
      font-size: 24px;
      padding-left: 0.1em;
    }

    .intro-card-performance-img {
      width: 110%;
    }
    .intro-cad-responsive-img {
      margin-left: 0;
      margin-top: 20px;
      min-height: 0;
    }
    .intro-card-code-wrap {
      font-size: 2.8vw;
    }
    .cta-link {
      font-size: 16px;
    }
    .cta-link.cc-reasons-banner {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 479px) {
    .container.container--hero {
      padding-top: 80px;
    }
    .container.container--large {
      padding-left: 0;
      padding-right: 0;
      width: 90%;
    }
    .section-heading-eyebrow {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .hero-heading {
      font-size: 54px;
    }
    .hero-img-container {
      padding: 8px;
    }
    .section-heading {
      font-size: 52px;
    }
    .section-heading.section-heading--faq {
      font-size: 44px;
    }
    .intro-card-description {
      margin-top: 14px;
    }

    .hero-eyebrow {
      font-size: 20px;
    }
    .intro-grid {
      grid-row-gap: 5vw;
      grid-template-columns: 1fr;
      margin-bottom: 5vw;
    }
    .intro-card {
      border-radius: 24px;
      padding: 38px 28px;
    }
    .intro-card.intro-grid-cell--animation {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
    }
    .intro-card.intro-card--integrations {
      padding-bottom: 38px;
    }
    .intro-card.intro-card--pixel {
      padding-bottom: 71px;
      padding-top: 71px;
    }
    .intro-card.intro-card--responsive {
      padding-bottom: 18px;
      padding-top: 32px;
    }
    .intro-card.intro-card--tiny {
      padding-left: 28px;
      padding-right: 28px;
      padding-top: 28px;
    }
    .intro-card.intro-card--tiny.cc-dev-speed {
      padding-bottom: 48px;
      padding-top: 63px;
    }
    .intro-card.intro-card--tiny.cc-code {
      padding-right: 5px;
    }
    .intro-card.intro-card--scale {
      height: 280px;
      padding-top: 28px;
    }
    .fluid-gradient-heading {
      font-size: 34px;
    }
    .fluid-gradient-heading.fluid-gradient-heading--footer {
      font-size: 10vw;
      margin-bottom: 10px;
    }
    .intro-card-heading,
    .intro-card-heading.intro-cell-heading--big {
      font-size: 28px;
    }
    .intro-card-heading.intro-card-heading--tiny {
      font-size: 16px;
    }
    .intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
      left: 28px;
      top: 32px;
    }
    .intro-card-heading.intro-card-heading--scale {
      font-size: 22px;
      left: 28px;
      max-width: 180px;
      top: 28px;
    }
    .stats-grid {
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }
    .intro-stats-figure {
      font-size: 48px;
    }
    .stats-descriptoin {
      font-size: 14px;
      margin-top: 2px;
    }
    .intro-stats-symbol {
      font-size: 20px;
    }
    .intro-stats-symbol.intro-stats-symbol--plus {
      line-height: 0.5;
    }

    .intro-card-performance-img {
      margin-left: -28px;
    }
    .intro-cad-responsive-img {
      margin-top: 28px;
      max-width: 100%;
    }
    .intro-card-code-wrap {
      font-size: 3.3vw;
      margin-right: -12px;
      margin-top: 30px;
    }
    .intro-card-code-indented {
      margin-left: 3vw;
    }
    .intro-card-scale-bar {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    .intro-card-dev-speed-wrap {
      font-size: 56px;
    }
    .cta-link-icon {
      height: 13.5px;
    }
  }

  @media only screen and (max-width: 359px) {
    .intro-card-heading {
      font-size: 24px;
    }
    .section-heading {
      font-size: 48px;
    }
  }

  @media only screen and (max-width: 1450px) and (min-width: 992px) {
    .intro-card-code-wrap {
      font-size: 1.45vw;
    }
    .intro-card-heading--scale {
      font-size: 24px;
    }
    .intro-card-dev-speed-wrap {
      font-size: 64px;
    }
  }
  @media only screen and (max-width: 991px) {
    .opened {
      border-radius: 0 !important;
    }
  }
  .section:focus {
    outline: 0;
  }
  .project-services-dot {
    background-color: currentColor;
  }
  .intro-stats-symbol--plus {
    vertical-align: top;
  }

  h3 {
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: -20px;
    max-width: 890px;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    margin: -6rem 0 6rem 0;
    h3 {
      font-size: 50px;
    }
  }
`;
