import React, { useEffect } from "react";
import { ContactWrapper } from "./FaqElements";
import "./Faq.css";
import ScrollAnimation from "react-animate-on-scroll";

function Faq() {
  useEffect(() => {
    const questionElements = document.querySelectorAll(".faq-question");

    const handleQuestionClick = (event) => {
      const question = event.currentTarget;
      const active = document.querySelector(".faq-question.active");

      if (active && active !== question) {
        active.classList.toggle("active");
        active.nextElementSibling.style.maxHeight = 0;
      }

      question.classList.toggle("active");
      const answer = question.nextElementSibling;

      if (question.classList.contains("active")) {
        answer.style.maxHeight = answer.scrollHeight + "px";
      } else {
        answer.style.maxHeight = 0;
      }
    };

    questionElements.forEach((question) => {
      question.addEventListener("click", handleQuestionClick);
    });

    return () => {
      questionElements.forEach((question) => {
        question.removeEventListener("click", handleQuestionClick);
      });
    };
  }, []);

  return (
    <ContactWrapper id="faq">
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <div className="faq-section">
            <div className="faq-section-header section-header--faq">
              <h3 className="faq-section-heading section-heading--faq">
                Frequently asked questions
              </h3>
            </div>

            <div className="faq-wrapper">
              {[
                {
                  question: "What specific services do you offer exactly ?",
                  answer:
                    "My focus is on MERN Stack development of websites based on screen designs. Additionally, I offer consulting services during your design phase.",
                },
                {
                  question: "How much typically does a project cost ?",
                  answer:
                    "Most of my projects range between $200 and ∞ My minimum engagement fee is $200.",
                },
                {
                  question: "Do you work internationally with global clients ?",
                  answer:
                    "Yes. I'm based in Sri Lanka and have clients in the US, Australia, Sri Lanka and Netherlands.",
                },
                {
                  question: "Do you offer any ongoing support services ?",
                  answer:
                    "Yes, I offer ongoing support and maintenance services to ensure your project remains secure, up to date and functions smoothly.",
                },
                {
                  question: "What are the payment terms for a project ?",
                  answer:
                    "50% upfront and 50% after launch. However, this payment schedule can be adjusted. ",
                },
                {
                  question: "How long does a typical project take ?",
                  answer:
                    "Most of my projects take between 2 weeks and 2 months.",
                },
              ].map((faq, index) => (
                <div className="faq-container" key={index}>
                  <div className="faq-question">
                    <div className="faq-question-text">{faq.question}</div>
                    <div className="faq-question-symbol"></div>
                  </div>
                  <div className="faq-answercont">
                    <div className="faq-answer">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Faq;
