import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
import ScrollAnimation from "react-animate-on-scroll";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About the Expert</div>
        <div className="BigCard">
          <ScrollAnimation animateIn="fadeInLeft">
            <Image src="/about.png" alt="man-svgrepo" />
          </ScrollAnimation>
          <div className="AboutBio">
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Hi! I am <strong>Tineth Pathirage</strong> from Colombo, Sri
                Lanka. I am a full stack developer with expertise in MERN Stack
                and a robust proficiency in cutting-edge Web3 and Blockchain
                development technologies. I turn your designs into pixel-perfect
                MERN sites with great attention to detail and elegant
                animations. Say goodbye to the stressful back and forth with
                developers who have no feel for design. Additionally, I offer
                consulting services during your design phase with my experience
                in UI/UX and animation.
              </p>
            </ScrollAnimation>

            <br />
            <br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Continuously refining my expertise as a full stack developer, I
                remain committed to staying abreast of the latest advancements
                and industry best practices. With meticulous attention to detail
                and a relentless pursuit of excellence, I thrive in dynamic
                environments where creative problem-solving and technical acumen
                converge. Through my unwavering dedication to continuous
                learning and professional growth, I continue to lead the
                forefront of innovation in full stack development, pushing the
                boundaries of what's achievable in software engineering.
              </p>
            </ScrollAnimation>

            <br />
            <br />

            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Working collaboratively within a team towards a shared objective
                has been an incredibly rewarding and unique experience for me. I
                am eager to continue exploring exciting projects in the future,
                with a particular interest in full stack development and
                blockchain development.
              </p>
              <div className="tagline2">
                <p>I have become confident using the following technologies:</p>
              </div>
            </ScrollAnimation>

            <Technologies>
              {stackList.map((stack, index) => (
                <ScrollAnimation animateIn="fadeInLeft" key={index}>
                  <Tech key={index} className="tech">
                    <TechImg src={stack.img} alt={stack.name} />
                    <p>
                      <TechName>{stack.name}</TechName>
                    </p>
                  </Tech>
                </ScrollAnimation>
              ))}
            </Technologies>
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;
