import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  margin: 10rem 0 6rem 0;
  .icons {
    position: relative;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }

  .social {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: #fff;
    margin: 12px;
    box-shadow: 0 5px 15px -5px #00000070;
    color: #000;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
  }

  .social i {
    line-height: 60px;
    font-size: 20px;
    transition: 0.5s linear;
  }

  .social:hover i {
    transform: scale(1.3);
    color: #fff;
  }

  .social:hover .icon {
    transform: scale(1.3);
    color: #fff;
  }

  .social::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: #000;
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
  }

  .social:hover::before {
    animation: aaa 1s 1;
    top: -10%;
    left: -10%;
  }

  @keyframes aaa {
    0% {
      left: -110%;
      top: 90%;
    }
    50% {
      left: 10%;
      top: -30%;
    }
    100% {
      top: -10%;
      left: -10%;
    }
  }

  @media screen and (max-width: 991px) {
    margin: 5rem 0 0rem 0;
    .social {
      display: inline-block;
      width: 35px;
      height: 35px;
      background: #fff;
      margin: 8px;
      box-shadow: 0 5px 10px -5px #00000070;
      color: #000;
      overflow: hidden;
      position: relative;
      border-radius: 50%;
    }

    .social i {
      line-height: 39px;
      font-size: 14px;
      transition: 0.5s linear;
    }

    .social:hover i {
      transform: scale(1.3);
      color: #fff;
    }

    .social::before {
      content: "";
      position: absolute;
      width: 120%;
      height: 120%;
      background: #000;
      transform: rotate(45deg);
      left: -110%;
      top: 90%;
    }

    .social:hover::before {
      animation: aaa 1s 1;
      top: -10%;
      left: -10%;
    }

    @keyframes aaa {
      0% {
        left: -110%;
        top: 90%;
      }
      50% {
        left: 10%;
        top: -30%;
      }
      100% {
        top: -10%;
        left: -10%;
      }
    }
  }
  @media (max-width: 768px) {
    .social {
      margin: 5px;
    }
    margin: 5rem 0 0rem 0;
  }
`;
