import React from "react";
import { Nav } from "./HeaderElements";

const Header = ({ toggle }) => {
  return (
    <div className="Container" style={{ padding: 0 }}>
      <Nav>
        <nav class="nav">
          <div class="container container--nav">
            <div class="nav__logo">
              <a
                href="/"
                aria-current="page"
                class="nav__logo__link w--current"
              >
                Tineth Pathirage
              </a>
            </div>
            <div class="nav__menu">
              <a href="#benefits" class="nav__link">
                Benefits
              </a>
              <a href="#about" class="nav__link">
                About
              </a>
              <a href="#projects" class="nav__link">
                Projects
              </a>
              <a href="#faq" class="nav__link">
                FAQ
              </a>
              <a href="/privacy-policy" class="nav__link">
                Privacy
              </a>
            </div>
            <a
              href="https://wa.me/+94763113219"
              target="_blank"
              class="cta-link cta__link--nav ix--cta w-inline-block"
              rel="noreferrer"
            >
              <div class="cta-link-text">Project request</div>
              <div class="cta-link-icon w-embed">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    stroke-width="3.5"
                    stroke="currentColor"
                    d="M8 20L16 12L8 4"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </nav>
      </Nav>
    </div>
  );
};

export default Header;
