import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  img {
    border: 0;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }
  svg:not(:root) {
    overflow: hidden;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }
  h1 {
    font-size: 38px;
    line-height: 44px;
  }
  h1,
  h2 {
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  h2 {
    font-size: 32px;
    line-height: 36px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
  }
  h3,
  h4 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }
  ul {
    margin-bottom: 10px;
    margin-top: 0;
    padding-left: 40px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  label,
  strong {
    font-weight: 500;
  }
  .nav {
    background-color: hsla(0, 0%, 100%, 0.7);
    border-bottom: 1px solid #e2e2e2;
    bottom: auto;
    left: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: 100%;
  }

  .container.container--center,
  .container.container--hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .container.container--large {
    max-width: 1440px;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
  }
  .container.container--reasons {
    max-width: 1092px;
  }
  .link__bg {
    background-color: #f7f7f7;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    bottom: 0;
    color: #1d1d1f;
    display: inline-block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
    top: 0;
  }

  .link__content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    z-index: 102;
  }
  .link__content:active {
    opacity: 0.7;
  }
  .cta-link {
    align-items: center;
    color: #006ddb;
    display: block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.025em;
    text-decoration: none;
    white-space: nowrap;
  }

  .cta-link.cta-link--hero.cc-reasons {
    color: #f5f5f7;
    padding: 0;
  }
  .section {
    padding-bottom: 140px;
    padding-top: 140px;
  }
  .section.section--gray.cc-reasons,
  .section.section--gray.section--faq {
    padding-top: 0;
  }
  .section.section--workflow {
    height: 100vh;
    overflow: hidden;
  }
  .section.section--manifest {
    padding-bottom: 160px;
    padding-top: 100px;
  }

  .section-heading {
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 890px;
  }

  .section-header {
    margin-bottom: 142px;
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    text-align: left;
    width: 90%;
  }

  .link {
    color: #006ddb;
    letter-spacing: -0.2px;
    text-transform: none;
  }

  .hero-eyebrow {
    font-size: 25px;
    letter-spacing: -0.03em;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: 0;
  }

  .cta-link-icon {
    align-items: center;
    display: inline-block;
    height: 13px;
    justify-content: center;
    margin-left: 3px;
    position: relative;
    top: 2px;
    width: 11px;
  }
  .cta-link-icon.cta-link-icon--hero {
    height: 18px;
    margin-left: 5px;
    top: 3px;
    width: 14px;
  }
  .cta-link-text {
    display: inline-block;
  }

  .no-underline {
    text-decoration: none;
  }
  .reasons-hero {
    padding-bottom: 164px;
    padding-top: 180px;
  }
  .reasons-hero-heading {
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -0.045em;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
  }
  .reasons-hero-heading.reasons-hero-heading--en {
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }
  .reasons-gradient-span {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(54deg, #b94cdb, #fe815e);
    margin-right: -5px;
    padding-right: 5px;
  }
  .reasons-grid {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }
  .reasons-cell {
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid hsla(0, 0%, 51%, 0.16);
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    min-height: 620px;
    overflow: hidden;
    padding: 52px 56px;
    position: relative;
  }
  .reasons-cell.reasons-cell--control {
    grid-column-gap: 28px;
    grid-row-gap: 16px;
    align-items: center;
    background-image: linear-gradient(50deg, #b94cdb, #e26092 52%, #fe815e);
    border-style: none;
    color: #f5f5f7;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    min-height: 0;
    padding-bottom: 120px;
    padding-top: 140px;
    position: relative;
  }
  .reasons-cell.reasons-cell--switch {
    padding-bottom: 220px;
  }
  .reasons-cell.reasons-cell--bonus {
    background-color: #1d1d1f;
    background-image: linear-gradient(45deg, #b94cdb, #fe815e);
    border-style: none;
    color: #f5f5f7;
    padding-bottom: 0;
    transform: perspective(2000px);
    transition: box-shadow 0.2s;
  }
  .reasons-cell.reasons-cell--bonus:hover {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);
  }
  .reason-eyebrow {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.1;
    margin-bottom: 26px;
    margin-top: 0;
  }
  .reason-eyebrow.reason-eyebrow--control {
    bottom: auto;
    left: 56px;
    position: absolute;
    right: auto;
    top: 52px;
  }
  .reason-heading {
    font-size: 48px;
    letter-spacing: -0.04em;
    line-height: 1.1;
    margin-bottom: 24px;
    margin-top: 0;
  }
  .reason-copy {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.025em;
    line-height: 1.35;
    opacity: 0.8;
  }
  .reason-copy.reason-copy--control {
    max-width: 390px;
  }
  .reasons-list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  .reasons-list.reasons-list--cms {
    margin-bottom: auto;
    margin-top: auto;
  }
  .reasons-list-item {
    align-items: center;
    display: flex;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.02em;
    margin-bottom: 22px;
    margin-top: 22px;
  }
  .reasons-list-item.reasons-list-item--small {
    font-size: 18px;
  }
  .reasons-check-icon {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: center;
    margin-right: 25px;
    width: 36px;
  }
  .reasons-check-icon.reasons-check-icon--small {
    height: 32px;
    margin-right: 18px;
    width: 32px;
  }
  .google-gradient {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(
      90deg,
      #ea4335,
      #fbbc05 33%,
      #4285f4 66%,
      #34a853
    );
  }
  .hosting-lottie {
    bottom: -295px;
    left: 0;
    position: absolute;
    right: 0;
    top: auto;
    width: 100%;
  }
  .reason-privacy-img {
    margin-top: 28px;
    width: 160px;
  }
  .reason-updates-img {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    width: 280px;
  }

  .reason-img-logos {
    margin-top: 58px;
  }
  .reason-img-logos.reason-img-logos--en {
    margin-top: 90px;
  }
  .memoji-img {
    align-self: center;
    margin-top: auto;
  }
  .reasons-numbers-track {
    bottom: 0;
    position: absolute;
    transform: translate(0);
  }
  .reasons-numbers-wrap {
    color: #c253c9;
    overflow: hidden;
    position: relative;
    width: 98px;
  }
  .reasons-heading-top {
    display: flex;
    justify-content: center;
  }
  .reason-3d-wrap {
    perspective: 1500px;
    transition: transform 0.3s;
  }
  .reason-3d-wrap a {
    text-decoration: none;
  }
  .reason-3d-wrap:hover {
    transform: scale(1.03);
  }
  .reasons-banner-heading {
    max-width: 440px;
  }
  .reason-seo-img {
    bottom: 0;
    max-width: none;
  }
  .reason-seo-img,
  .reason-switch-img {
    left: 0;
    position: absolute;
    right: 0;
    top: auto;
    width: 100%;
  }
  .reason-switch-img {
    bottom: 5%;
  }
  .reason-switch-img.cc-en {
    bottom: 10%;
  }
  .reaons-banner-wrap {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scaleX(1none) translate(0);
    transform-style: preserve-3d;
    transition: transform 0.2s;
  }
  .reaons-banner-wrap:hover {
    transform: scale(1.02);
  }
  @media screen and (max-width: 991px) {
    a {
      cursor: auto;
    }

    .hero-heading {
      font-size: 72px;
    }
    .section {
      padding-bottom: 80px;
      padding-top: 80px;
    }
    .section.section--manifest {
      display: none;
    }
    .section-heading {
      font-size: 72px;
      max-width: 650px;
    }

    .section-header {
      margin-bottom: 80px;
    }

    .settings-wrap {
      bottom: 12px;
    }
    .settings__inner {
      border-radius: 10px;
    }

    .hero-eyebrow {
      font-size: 24px;
      font-weight: 500;
    }

    .cta-link-icon.cta-link-icon--hero {
      height: 17px;
      width: 13px;
    }
    .section-heading-eyebrow {
      font-size: 26px;
    }

    .hero-heading-ui-wrap {
      margin-bottom: 18px;
    }

    .reasons-hero-heading {
      font-size: 60px;
    }
    .reasons-hero-heading.reasons-hero-heading--en {
      max-width: 610px;
    }
    .reasons-grid {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
    .reasons-cell {
      min-height: 550px;
      padding: 40px 32px;
    }
    .reason-eyebrow {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .reason-eyebrow.reason-eyebrow--control {
      left: 32px;
      top: 40px;
    }
    .reason-heading {
      font-size: 42px;
      margin-bottom: 22px;
    }
    .reason-copy,
    .reasons-list-item {
      font-size: 18px;
    }
    .reasons-check-icon {
      height: 32px;
      margin-right: 18px;
      width: 32px;
    }
    .hosting-lottie {
      bottom: -42%;
    }
    .reason-updates-img {
      margin-bottom: 0;
      width: 250px;
    }
    .reasons-numbers-wrap {
      width: 70px;
    }
  }
  @media screen and (max-width: 767px) {
    .cta-link {
      font-size: 16px;
    }
    .cta-link.cta__link--nav {
      position: static;
    }
    .cta-link.cta-link--form {
      font-size: 18px;
    }
    .cta-link.cc-reasons-banner {
      margin-top: 10px;
    }
    .hero-heading {
      font-size: 64px;
    }
    .section-heading {
      font-size: 56px;
    }
    .section-heading.section-heading--faq {
      font-size: 48px;
    }

    .hero-eyebrow {
      font-size: 22px;
    }

    .reasons-hero {
      padding-bottom: 100px;
      padding-top: 120px;
    }
    .reasons-grid {
      grid-template-columns: 1fr;
    }
    .reasons-cell {
      min-height: 0;
    }
    .reasons-cell.reasons-cell--control {
      grid-template-columns: 1fr;
      padding-bottom: 40px;
      padding-top: 40px;
    }
    .reasons-cell.reasons-cell--seo {
      height: 80vw;
      min-height: 500px;
    }
    .reasons-cell.reasons-cell--switch {
      background-size: 102%;
    }
    .reasons-cell.reasons-cell--bonus:hover {
      box-shadow: none;
    }
    .reasons-cell.reasons-cell--hosting {
      padding-bottom: 200px;
    }
    .reason-eyebrow.reason-eyebrow--control {
      position: static;
    }
    .reasons-list.reasons-list--cms {
      margin-bottom: 0;
      margin-top: 0;
    }
    .reasons-list-item.reasons-list-item--small {
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .hosting-lottie {
      bottom: -237px;
      margin-left: auto;
      margin-right: auto;
      width: 420px;
    }
    .reason-updates-img {
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 250px;
    }
    .reason-img-logos.reason-img-logos--en {
      margin-bottom: 0;
      margin-top: 58px;
    }
    .reason-3d-wrap {
      perspective: none;
    }
    .reason-3d-wrap:hover {
      transform: none;
    }
    .reason-switch-img.cc-en {
      bottom: 5%;
    }
  }
  @media screen and (max-width: 479px) {
    .container.container--large {
      padding-left: 0;
      padding-right: 0;
      width: 90%;
    }
    .cta-link.cta-link--hero {
      font-size: 18px;
    }

    .hero-heading {
      font-size: 54px;
    }

    .section-heading {
      font-size: 52px;
    }

    .settings-handle {
      justify-content: flex-end;
    }
    .hero-eyebrow {
      font-size: 20px;
    }

    .cta-link-icon {
      height: 13.5px;
    }
    .cta-link-icon.cta-link-icon--hero {
      height: 16px;
      margin-left: 4px;
      width: 12px;
    }

    .section-heading-eyebrow {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .reason-updates-img {
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
    }

    .reasons-hero-heading {
      font-size: 48px;
    }
    .reasons-grid {
      grid-row-gap: 5vw;
    }
    .reasons-cell {
      border-radius: 24px;
      padding: 40px 28px;
    }
    .reasons-cell.reasons-cell--control {
      grid-row-gap: 24px;
    }
    .reasons-cell.reasons-cell--seo {
      min-height: 480px;
    }
    .reasons-cell.reasons-cell--switch {
      padding-bottom: 160px;
    }
    .reasons-cell.reasons-cell--hosting {
      padding-bottom: 180px;
    }
    .reason-eyebrow {
      margin-bottom: 16px;
    }
    .reason-heading {
      font-size: 40px;
      margin-bottom: 18px;
    }
    .reason-copy {
      font-size: 18px;
      line-height: 1.4;
    }
    .reasons-list-item.reasons-list-item--small {
      font-size: 16px;
      margin-bottom: 22px;
      margin-top: 22px;
    }
    .reasons-check-icon {
      height: 28px;
      margin-right: 16px;
      width: 28px;
    }
    .reasons-check-icon.reasons-check-icon--small {
      height: 28px;
      margin-right: 14px;
      width: 28px;
    }
    .hosting-lottie {
      bottom: -152px;
      width: 320px;
    }
    .reason-privacy-img {
      width: 120px;
    }
    .memoji-img,
    .reason-img-logos,
    .reason-img-logos.reason-img-logos--en {
      margin-top: 32px;
    }
    .memoji-img {
      width: 240px;
    }
    .reasons-numbers-wrap {
      width: 58px;
    }
  }
  #node-ui,
  #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58,
  #w-node-cc4a8789-0374-689f-3203-0e56cece0e17-178be24a,
  #w-node-cc4a8789-0374-689f-3203-0e56cece0e17-cf6845d2 {
    -ms-grid-row-span: 1;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    grid-column-start: span 2;
    grid-row-end: span 1;
    grid-row-start: span 1;
  }
  #w-node-_4ba720d1-a98d-161a-92f5-36e6e21015f4-06f6bd58,
  #w-node-_4ba720d1-a98d-161a-92f5-36e6e21015f4-28f29210 {
    justify-self: center;
  }
  #node-8-reasons,
  #w-node-cd34ee28-be63-79d9-821e-a414d8157c05-06f6bd58 {
    -ms-grid-column-span: 2;
    -ms-grid-row-span: 1;
    grid-column-end: span 2;
    grid-column-start: span 2;
    grid-row-end: span 1;
    grid-row-start: span 1;
  }
  #node-project-list-item,
  #w-node-_671cd3a2-7408-189b-b9c0-8ea917a5ec73-06f6bd58 {
    align-self: stretch;
    justify-self: stretch;
  }
  @media screen and (max-width: 991px) {
    #node-ui,
    #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58 {
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      grid-row-start: span 1;
    }
    #node-8-reasons,
    #node-ui,
    #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58,
    #w-node-cd34ee28-be63-79d9-821e-a414d8157c05-06f6bd58 {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      grid-column-start: span 1;
    }
  }
  @media screen and (max-width: 767px) {
    #w-node-cc4a8789-0374-689f-3203-0e56cece0e17-178be24a,
    #w-node-cc4a8789-0374-689f-3203-0e56cece0e17-cf6845d2 {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      grid-column-start: span 1;
    }
  }
  @media screen and (max-width: 479px) {
    #node-clean-code,
    #node-clients,
    #node-duration,
    #node-integrations,
    #node-pixel-perfect,
    #node-responsive-design,
    #node-scalable-cms,
    #node-speed,
    #node-stat,
    #node-theme-developer,
    #node-ui,
    #w-node-_0f890481-a69b-81c6-4795-e7f66696b276-178be24a,
    #w-node-_0f890481-a69b-81c6-4795-e7f66696b276-cf6845d2,
    #w-node-_10a1a4da-5d2d-cb83-41b6-3638d47e2759-06f6bd58,
    #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e8e-06f6bd58,
    #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e92-06f6bd58,
    #w-node-_621f5c15-aa3a-ea36-1d24-9d852355afac-06f6bd58,
    #w-node-_7343152d-f711-deb4-0118-197cfa067569-06f6bd58,
    #w-node-_76134843-f7b6-aac7-450b-aea7336dc74c-06f6bd58,
    #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58,
    #w-node-_84b8e937-c045-408b-d680-33095298155a-06f6bd58,
    #w-node-_9d0fa4dc-f9bb-4008-1cb5-da9f049a0761-06f6bd58,
    #w-node-cd10a809-fa1b-95ba-53a0-cdc422824f04-06f6bd58,
    #w-node-f351f543-f0f7-460b-07e1-fd5780deec32-06f6bd58 {
      -ms-grid-row-span: 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      grid-column-start: span 1;
      grid-row-end: span 1;
      grid-row-start: span 1;
    }
  }

  .hero-eyebrow,
  .hero-heading {
    will-change: transform, opacity;
  }

  .hero-eyebrow {
    animation: fadeIn 0.8s 0.4s backwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: auto;
      transform: translateY(0);
    }
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: inherit;
  }
  .visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    -webkit-clip-path: inset(0 0 99.9% 99.9%);
    clip-path: inset(0 0 99.9% 99.9%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  @media only screen and (max-width: 359px) {
    .section-heading {
      font-size: 48px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .hero-heading {
      font-size: 120px;
    }
    .hero-eyebrow {
      font-size: 32px;
    }
    .hero-img-wrap {
      margin-bottom: 80px;
    }
    .cta-link.cta-link--hero {
      font-size: 27px;
    }
    .hero .cta-link-icon.cta-link-icon--hero {
      height: 21px;
      width: 17px;
    }
  }
  @media only screen and (min-width: 992px) and (max-height: 800px) {
    .hero-heading {
      font-size: 80px;
    }
  }

  @media only screen and (max-width: 991px) {
    .opened {
      border-radius: 0 !important;
    }
  }
  .section:focus {
    outline: 0;
  }

  @media only screen and (max-width: 1100px) and (min-width: 992px) {
    .cc-de {
      font-size: 80px !important;
    }
    .cc-en {
      font-size: 92px !important;
    }
  }
  .reasons-numbers-track {
    animation: numbersCount 1.8s cubic-bezier(0.15, 1.1, 1, 1.035);
    animation-delay: 0.4s;
    animation-fill-mode: backwards;
    animation-iteration-count: 1;
    will-change: transform;
  }
  .reasons-numbers-wrap {
    animation: numbersBounce 0.1s cubic-bezier(1, 0.6, 1, 0.7);
    animation-delay: 2.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    will-change: transform;
  }
  .reasons-heading-top {
    animation: topOpacity 0.8s;
    animation-delay: 0.4s;
    animation-fill-mode: backwards;
    animation-iteration-count: 1;
  }
  @keyframes numbersCount {
    0% {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes numbersBounce {
    0% {
      transform: translateY(-2px);
    }
    to {
      transform: translateY(0);
    }
  }
  @keyframes topOpacity {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 320px) {
    .reason-heading {
      font-size: 34px;
    }
  }
`;
