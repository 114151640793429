import React from "react";
import { ContactWrapper } from "./SocialElements";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaDiscord,
  FaStackOverflow,
  FaGithub,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

import ScrollAnimation from "react-animate-on-scroll";
function Social() {
  return (
    <ContactWrapper id="contact">
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <div class="icons">
            <a
              class="social"
              href="https://facebook.com/tineth.pathirage"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaFacebookF class="icon" />
              </i>
            </a>
            <a
              class="social"
              href="https://www.instagram.com/mr_tineth/"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaInstagram class="icon" />
              </i>
            </a>
            <a
              class="social"
              href="https://www.linkedin.com/in/tineth-pathirage-11916a265/"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaLinkedinIn class="icon" />
              </i>
            </a>
            <a
              class="social"
              href="https://twitter.com/TinethPathirage"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <BsTwitterX class="icon" />
              </i>
            </a>
            <a
              class="social"
              href="https://github.com/MrTineth"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaGithub class="icon" />
              </i>
            </a>

            <a
              class="social"
              href="https://stackoverflow.com/users/25156432/tineth-pathirage"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaStackOverflow class="icon" />
              </i>
            </a>
            <a
              class="social"
              href="https://discordapp.com/users/1242942024903164047"
              target="_blank"
              rel="noreferrer"
            >
              <i>
                <FaDiscord class="icon" />
              </i>
            </a>
          </div>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Social;
