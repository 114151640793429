import React from "react";
import { useEffect } from "react";
import Hero from "../components/Hero-Privacy/Hero";
import PrivacyDetails from "../components/Privacy/Privacy";
import Footer from "../components/Footer/Footer";
import ScrollToTop from "../components/Scroll/ScrollToTop";

const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);
  return (
    <>
      <Hero />
      <PrivacyDetails />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Privacy;
