import styled from "styled-components";

export const HeroContainer = styled.div`
  * {
    box-sizing: border-box;
  }

  .hero {
    background-color: #ffffff;
    color: #1d1d1f;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    min-height: 100%;
  }
  h1 {
    font-size: 38px;
    line-height: 44px;
  }
  h1,
  h2 {
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 0px;
  }
  h2 {
    font-size: 25px;
    line-height: 36px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-top: 20px;
  }
  h3,
  h4 {
    font-weight: 600;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 980px;
    width: 90%;
  }

  .container.container--center,
  .container.container--hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .container.container--hero {
    bottom: auto;
    height: 100vh;
    justify-content: center;
    left: 0;
    max-width: none;
    margin-bottom: 100px;
    padding-top: 60px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .container.container--large {
    max-width: 1440px;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
  }
  .link__bg.link__bg--hero {
    border-radius: 12px;
  }
  .cta-link {
    align-items: center;
    color: #006ddb;
    display: block;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.025em;
    text-decoration: none;
    white-space: nowrap;
  }
  .cta-link.cta-link--hero {
    font-size: 23px;
    padding: 10px 28px;
    position: relative;
    z-index: 10;
  }
  .hero {
    height: 100vh;
  }
  .hero,
  .hero__header {
    align-items: center;
    display: flex;
  }
  .hero__header {
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .hero-heading {
    font-size: 92px;
    letter-spacing: -0.045em;
    line-height: 1.05;
    margin-bottom: 0;
    margin-top: 0;
  }
  .hero-img-container {
    background-color: hsla(0, 0%, 84%, 0.5);
    border: 1px solid hsla(0, 0%, 100%, 0.3);
    border-radius: 50%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    padding: 12px;
    position: relative;
    width: 100%;
    z-index: 3;
  }

  .hero-eyebrow {
    animation: fadeIn 0.8s 0.4s backwards;
  }
  .hero-eyebrow {
    font-size: 25px;
    letter-spacing: -0.03em;
    line-height: 1.2;
    margin-bottom: 0;
    margin-top: 0;
  }

  .hero-img-wrap {
    height: 25vw;
    margin-bottom: 64px;
    max-height: 40vh;
    max-width: 40vh;
    min-height: 350px;
    min-width: 350px;
    position: relative;
    width: 25vw;
    z-index: 2;
  }
  .hero-img-shadow {
    background-image: radial-gradient(
        circle farthest-side at 100% 100%,
        #9873ff,
        rgba(152, 115, 255, 0)
      ),
      radial-gradient(
        circle farthest-side at 100% 0,
        #0ba5f7,
        rgba(85, 196, 255, 0)
      ),
      radial-gradient(
        circle farthest-side at 0 0,
        #ff763c,
        rgba(255, 176, 60, 0)
      ),
      radial-gradient(
        circle farthest-side at 0 100%,
        #ff5aaa,
        rgba(255, 90, 170, 0)
      );
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
  .hero-img-shadow-wrap {
    border-radius: 50%;
    bottom: 0;
    filter: blur(20px);
    height: 140%;
    left: -20%;
    padding: 20%;
    position: absolute;
    right: 0;
    top: -20%;
    width: 140%;
  }
  .hero-img-shadow-wrap.hero-img-shadow-wrap--intro {
    z-index: 1;
  }
  .settings-handle {
    align-items: flex-end;
    background-color: hsla(0, 0%, 59%, 0.3);
    border-radius: 24px;
    display: flex;
    font-size: 14px;
    height: 44px;
    justify-content: flex-start;
    padding: 12px 26px;
    width: 72px;
  }
  .hero-img {
    border-radius: 50%;
    display: block;
    height: 100%;
    max-width: none;
    width: 100%;
  }
  .cta-link-icon {
    align-items: center;
    display: inline-block;
    height: 13px;
    justify-content: center;
    margin-left: 3px;
    position: relative;
    top: 2px;
    width: 11px;
  }
  .cta-link-icon.cta-link-icon--hero {
    height: 18px;
    margin-left: 10px;
    top: 3px;
    width: 14px;
  }
  .cta-link-text {
    display: inline-block;
  }
  .hero-scroll-wrap {
    position: relative;
  }
  .hero-heading-ui-wrap {
    margin-bottom: 22px;
    margin-top: 18px;
    position: relative;
  }
  .hero-heading-ui {
    border: 1px solid #168bf4;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .hero-heading-ui-tab {
    align-items: center;
    background-color: #168bf4;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    bottom: auto;
    color: #fff;
    display: flex;
    font-size: 11px;
    height: 20px;
    left: -1px;
    padding: 3px 12px 2px 8px;
    position: absolute;
    right: auto;
    top: -20px;
  }
  .hero-ui {
    font-size: 5vw;
    left: 50%;
    margin-left: -8.45em;
    margin-top: 3.2em;
    max-width: none;
    opacity: 0;
    position: relative;
    width: 16.9em;
    z-index: 1;
  }

  .hero-scroll-trigger {
    bottom: auto;
    height: 100vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .hero-heading-ui-tab-h1 {
    margin-right: 4px;
  }
  .hero-paragraph-mobile {
    display: none;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 1.25;
  }

  @media screen and (max-width: 991px) {
    .container.container--hero {
      height: auto;
      justify-content: space-around;
      min-height: 100vh;
      padding-bottom: 5px;
      padding-top: 120px;
      position: static;
    }
    .hero {
      height: auto;
    }
    .hero-heading {
      font-size: 72px;
    }
    .section {
      padding-bottom: 80px;
      padding-top: 80px;
    }
    .section.section--manifest {
      display: none;
    }
    .section-heading {
      font-size: 72px;
      max-width: 650px;
    }
    .sticky-wrap.sticky-wrap--hero {
      height: auto;
      min-height: 0;
      position: static;
    }

    .section-header {
      margin-bottom: 80px;
    }

    .cta-link.cta-link--hero {
      font-size: 20px;
    }

    .hero-img-wrap {
      height: 45vh;
      min-height: 0;
      min-width: 0;
      width: 45vh;
    }

    .hero-eyebrow {
      font-size: 24px;
      font-weight: 500;
    }

    .intro-card-heading.intro-cell-heading--big {
      font-size: 40px;
    }
    .cta-link-icon.cta-link-icon--hero {
      height: 17px;
      width: 13px;
    }
    .section-heading-eyebrow {
      font-size: 26px;
    }

    .hero-heading-ui-wrap {
      margin-bottom: 18px;
    }
    .hero-heading-ui,
    .hero-scroll-trigger,
    .hero-ui,
    .sticky-gap {
      display: none;
    }
    .hero-paragraph-mobile {
      display: block;
      margin-bottom: 10px;
      max-width: 400px;
    }
  }
  @media screen and (max-width: 767px) {
    .container.container--hero {
      margin-bottom: 14px;
      padding-top: 80px;
    }
    .cta-link {
      font-size: 16px;
    }

    .cta-link.cc-reasons-banner {
      margin-top: 10px;
    }
    .hero-heading {
      font-size: 64px;
    }
    .section-heading {
      font-size: 56px;
    }

    .hero-img-wrap {
      height: 350px;
      width: 350px;
    }
    .hero-eyebrow {
      font-size: 22px;
    }

    .section-heading-eyebrow {
      font-size: 24px;
      padding-left: 0.1em;
    }

    .hero-paragraph-mobile {
      font-size: 22px;
      max-width: 300px;
    }
  }
  @media screen and (max-width: 479px) {
    .container.container--hero {
      padding-top: 100px;
      padding-bottom: 20px;
    }
    .container.container--large {
      padding-left: 0;
      padding-right: 0;
      width: 90%;
    }
    .cta-link.cta-link--hero {
      font-size: 18px;
    }
    .hero {
      overflow: hidden;
    }
    .hero-heading {
      font-size: 54px;
    }
    .hero-img-container {
      padding: 8px;
    }
    .section-heading {
      font-size: 52px;
    }

    .inquiry-intro {
      border-radius: 0;
    }
    .inquiry-nav-button-wrap {
      right: 6px;
      top: 12px;
    }
    .inquiry-nav-button-wrap:hover {
      border-radius: 8px;
    }
    .inquiry-nav-button-wrap.inquiry-nav-button-wrap--back {
      left: 6px;
      top: 12px;
    }

    .hero-img-wrap {
      height: 90%;
      margin-bottom: 42px;
      width: 90%;
    }
    .hero-img-shadow-wrap {
      filter: blur(15px);
    }
    .settings-handle {
      justify-content: flex-end;
    }
    .hero-eyebrow {
      font-size: 20px;
    }

    .intro-card-heading,
    .intro-card-heading.intro-cell-heading--big {
      font-size: 28px;
    }
    .intro-card-heading.intro-card-heading--tiny {
      font-size: 16px;
    }

    .intro-card-heading.intro-card-heading--scale {
      font-size: 22px;
      left: 28px;
      max-width: 180px;
      top: 28px;
    }

    .cta-link-icon {
      height: 13.5px;
    }
    .cta-link-icon.cta-link-icon--hero {
      height: 16px;
      margin-left: 4px;
      width: 12px;
    }

    .section-heading-eyebrow {
      font-size: 22px;
      margin-bottom: 12px;
    }

    .hero-heading-ui-wrap {
      margin-bottom: 18px;
    }
    .hero-paragraph-mobile {
      font-size: 20px;
      max-width: 300px;
    }
  }

  .hero-img {
    animation: heroImgScale 0.8s 0.8s backwards;
    will-change: transform;
  }
  @keyframes heroImgScale {
    0% {
      transform: scale(2);
    }
    to {
      transform: scale(1);
    }
  }
  #hero-img-shadow-1 {
    animation: heroImgShadow1 0.8s 1.5s backwards;
    will-change: opacity;
  }
  @keyframes heroImgShadow1 {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  #hero-img-shadow-2 {
    animation: heroImgShadow2 1.2s cubic-bezier(0.65, 0, 0.35, 1);
    animation-delay: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    will-change: opacity, transform;
  }
  @keyframes heroImgShadow2 {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    99% {
      height: 140%;
      opacity: 0;
      transform: scale(6);
    }
    to {
      height: 0;
      opacity: 0;
    }
  }
  .hero-img-wrap {
    animation: heroImgWrap 0.8s 0.8s backwards;
    will-change: opacity;
  }
  .hero-eyebrow,
  .hero-heading {
    will-change: transform, opacity;
  }
  @keyframes heroImgWrap {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .hero-eyebrow {
    animation: fadeIn 0.8s 0.4s backwards;
  }
  .hero-heading {
    animation: fadeIn 0.8s 0.5s backwards;
  }
  .cta-link--hero,
  .hero-paragraph-mobile {
    animation: fadeIn 0.8s 0.6s backwards;
    will-change: transform, opacity;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: auto;
      transform: translateY(0);
    }
  }

  @keyframes intro-gradient {
    0% {
      background-position: 0 4%;
    }
    50% {
      background-position: 100% 97%;
    }
    to {
      background-position: 0 4%;
    }
  }

  @media only screen and (max-width: 359px) {
    .intro-card-heading {
      font-size: 24px;
    }
    .section-heading {
      font-size: 48px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .hero-heading {
      font-size: 120px;
    }
    .hero-eyebrow {
      font-size: 32px;
    }
    .hero-img-wrap {
      margin-bottom: 80px;
    }
    .cta-link.cta-link--hero {
      font-size: 27px;
    }
    .hero .cta-link-icon.cta-link-icon--hero {
      height: 21px;
      width: 17px;
    }
  }
  @media only screen and (min-width: 992px) and (max-height: 800px) {
    .hero-heading {
      font-size: 80px;
    }
    .hero-img-wrap {
      height: 280px;
      margin-bottom: 48px;
      max-height: 280px;
      max-width: 280px;
      min-height: 280px;
      min-width: 280px;
      width: 280px;
    }
  }
  @media only screen and (max-width: 1450px) and (min-width: 992px) {
    .intro-card-code-wrap {
      font-size: 1.45vw;
    }
    .intro-card-heading--scale {
      font-size: 24px;
    }
    .intro-card-dev-speed-wrap {
      font-size: 64px;
    }
  }
  @media only screen and (max-width: 991px) {
    .opened {
      border-radius: 0 !important;
    }
  }
  .section:focus {
    outline: 0;
  }
`;
